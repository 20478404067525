import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useState } from "react";
import Stripe from "stripe";

import "./style.css";

const stripe = new Stripe(
  "sk_test_51IlDRWC3WgZnxlSdffwxsbGX5NhGfjh3RgKEXmPiEKMp0ikDvCOM8lV8hFYYlz0uHhWgenyrEaEpSpO59db1zMMp00J1aHb90P"
);
const checkoutFormOptions = {
  style: {
    base: {
      fontSize: "16px",

      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
  hidePostalCode: true,
};

const CheckoutForm = ({ success = () => {} }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: "",
  });
  const amount = 1000;

  async function handleSubmit(e) {
    e.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });

    try {
      const payment = await stripe.paymentIntents.create({
        payment_method: paymentMethod.id,
        amount,
        currency: "USD",
        description: `Order from lev jan}`,
        confirm: true,
      });
    } catch (e) {
      console.log(e, "pay err");
    }
    if (error) {
      console.log(error);
    } else {
      console.log(paymentMethod);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input
          type="email"
          style={{ backgroundColor: "transparent", width: "90%" }}
          placeholder="Your Email"
          value={billingDetails.email}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, email: e.target.value });
          }}
        />
        <input
          style={{
            backgroundColor: "transparent",
            width: "90%",
            marginTop: "5px",
            marginBottom: "5px",
          }}
          placeholder="Your Name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}
        />
        <input
          type="number"
          style={{
            backgroundColor: "transparent",
            width: "90%",
            marginTop: "5px",
            marginBottom: "5px",
          }}
          placeholder="Your Phone Number"
          value={billingDetails.phone}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, phone: e.target.value });
          }}
        />
      </div>
      <CardElement options={checkoutFormOptions} />

      <button className="square_btn" type="submit">
        Pay
      </button>
    </form>
  );
};

export default CheckoutForm;
