import React, { useState } from "react";
import Footer from "../Footer";

const OurTrainers = () => {
  const [size, setSize] = useState(window.innerWidth);
  window.addEventListener("resize", () => setSize(window.innerWidth));
  const trainersData = [
    {
      name: "Narbeh Ebrahimian",
      photo: "/images/Narbeh.jpg",
      position: "Head Trainer",
      about:
        "Narbeh Ebrahimian graduated from San Diego State University (SDSU) with a Kinesiology degree and a Fitness Specialist emphasis. He played four years of college basketball, two of which were at a Division 1 level at SDSU. He is an American College of Sports Medicine (ACSM) certified personal trainer (CPT) and owner of Performance Academy, LLC. His program includes group cardio and muscle sculpting classes, yoga classes, small group training, and one-on- one personal training. He also works with athletes to improve agility, power, speed, and explosiveness. Athletes are trained as a team, in a small group, or privately. Although a well-rounded athlete, Narbeh’s expertise lies in basketball specific training. Whether it be an improvement in vertical jump, lateral movement, ball handling, or shooting form, he helps athletes develop the skill set required to improve their game and take it to the next level",
    },
    {
      name: "Greg Keshishian",
      photo: "/images/Greg.jpg",
      position: "Trainer",
      about:
        "Gregory Keshishian is currently attending Central State University in Ohio to get his teaching credentials. Greg works for Glendale unified school district as a PE teacher. He is going to be pursuing his masters degree in education in the coming year. Growing up Greg was a member in Ararat and played basketball and soccer. He also began his coaching career soon after turning 18. Greg was also a part of the pan Armenia and homenetmen soccer and basketball teams. ",
    },
  
  ];
  const styleBlock =
    size <= 940
      ? {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          margin: "auto",
          marginTop: "50px",
          marginBottom: "200px",
        }
      : {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignItems: "center",
          width: "80%",
          margin: "auto",
          marginTop: "50px",
          marginBottom: "200px",
        };
  return (
    <div>
      <h1 style={{ margin: "50px" }}>Our Trainers</h1>

      {trainersData.map((person) => {
        return (
          <div style={styleBlock}>
            <img src={person.photo} width="20%" />
            <div style={{ width: "70%" }}>
              <p style={{ fontWeight: "bold", fontSize: "22px" }}>
                {person.name}
              </p>
              <p style={{ fontStyle: "italic", fontSize: "20px" }}>
                {person.position}
              </p>
              <p style={{ fontFamily: "Trebuchet MS" }}>{person.about}</p>
            </div>
          </div>
        );
      })}
      <Footer />
    </div>
  );
};
export default OurTrainers;
