import React, { useState } from "react";
import PayWithPayPal from "../pages/shop/payPal";
import PayButton from "../pages/shop/stripe/paymentStripebutton";
import "./style.css";
const Checkout = ({ data, showPopUp }) => {
  const [value, setValue] = useState("");
  const [pay, setPay] = useState("");

  if (pay === "payPal") {
    return (
      <div className="popUp">
        <PayWithPayPal
          items={[{ ...data }]}
          total={data.value}
          showPopUp={showPopUp}
        />
      </div>
    );
  }
  if (pay === "stripe") {
    return (
      <div className="popUpStripe">
        <PayButton />
      </div>
    );
  }
  return (
    <div className="popUp" onClick={(e) => e.stopPropagation()}>
      <div>
        <input
          type="radio"
          value="payPal"
          name="checkout"
          title="Pay Pal"
          checked={value === "payPal"}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />{" "}
        <img
          src="https://icons-for-free.com/iconfiles/png/512/payment+online+transaction+payment+method+paypal+icon-1320186277596893768.png"
          style={{ width: "50%", height: "20%" }}
          alt=""
        />
      </div>
      {/* <div>
        <input
          type="radio"
          name="checkout"
          value="stripe"
          checked={value === "stripe"}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <img
          style={{ width: "50%", height: "20%" }}
          src="https://uxwing.com/wp-content/themes/uxwing/download/10-brands-and-social-media/stripe.png"
        />
      </div> */}
      <button disabled={!value} onClick={() => setPay(value)}>
        Pay
      </button>
    </div>
  );
};
export default Checkout;
