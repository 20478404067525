import React, { useState, useEffect, useRef } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

function PayWithPayPal(props) {
  const { items, total, showPopUp } = props;
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const paypalRef = useRef();

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: items[0].name,
                amount: {
                  currency_code: "USD",
                  value: items[0].value,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          setPaidFor(true);
          console.log("ORDER", order);
        },
        onError: (err) => {
          setError(err);
          console.error("ERROR", err);
        },
      })
      .render(paypalRef.current);
  }, []);

  if (paidFor) {
    alert(" Thanks for making the purchase.");
    showPopUp();
    setPaidFor(false);
  }

  if (error) {
    alert("Error in processing order. Please Retry again");
    showPopUp();
    setPaidFor(false);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        ref={paypalRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
        }}
      />
    </div>
  );
}

export default PayWithPayPal;
