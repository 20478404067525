import React, { useRef } from "react";
import "../../App.css";
import Calendar from "react-calendar";
import Form from "../../Form";

export default function SignUp() {
  return (
    <div>
      <Form />
    </div>
  );
}

// return <h1 className='sign-up'>LIKE & SUBSCRIBE</h1>;

// const emailRef =useRef()
// const passwordRef =useRef()
// const passwordConfirmRef =useRef()

// import {Card, Form, Button } from 'react-bootstrap'
// return(
//   <>
//   <Container className="d-flex align-items-center jusify-content-center" style={{minHeight:"100vh"}}>
//     <div className="w-100" style={{maxWidth: "400px"}}>
//     </div>
//   </Container>

//     <Card>
//       <Card.Body>
//         <h2 className="text-center mb-4">Sign Up</h2>
//         <Form>
//           <Form.Group id = "email">
//             <Form.Label>  Email</Form.Label>
//             <Form.Control type="email" ref={emailRef} required />
//              </Form.Group>
//           <Form.Group id = "password">
//             <Form.Label>  Password</Form.Label>
//             <Form.Control type="password" ref={passwordRef} required />
//           </Form.Group>
//           <Form.Group id = "password-confirm">
//             <Form.Label>  Password Confirmation</Form.Label>
//             <Form.Control type="password" ref={passwordConfirmRef} required />
//           </Form.Group>

//           <Button className="w-100" type="submit"> Sign Up </Button>
//         </Form>
//       </Card.Body>
//     </Card>
//     <div className= "w-100 text-center mt-2">
//       Already have an account? Log In
//     </div>
//   </>
// )
