import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <h1>We Offer</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          {/* <ul className='cards__items'>
            <CardItem
              src='images/img-9.jpg'
              text='Explore the hidden waterfall deep inside the Amazon Jungle'
              label='Adventure'
              path='/services'
            />
            <CardItem
              src='images/img-2.jpg'
              text='Travel through the Islands of Bali in a Private Cruise'
              label='Luxury'
              path='/services'
            />
          </ul> */}
          <ul className="cards__items">
            <CardItem
              src="images/narb.JPG"
              text="Group Fitness Classes"
              label="Fitness"
              path="/classchedule"
            />
            <CardItem
              src="images/IMG_8833.JPG"
              text="1-ON-1 Performance Training for Athletes "
              label="Athlete"
              path="classchedule"
            />
            {/* <CardItem
              src="images/img-8.jpg"
              text="Ride through the Sahara Desert on a guided camel tour"
              label="Adrenaline"
              path="/sign-up"
            /> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
