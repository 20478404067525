import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <div className="nav">
        <nav className="navbar">
          <div className="navbar-container">
            {/* <Link to='/' className='navbar-logo' onClick={closeMobileMenu}> */}
            <img src="/images/WEBLOGO.png" width={182} height={182} alt="" />
            {/* <i class='fab fa-typo3' /> */}
            {/* </Link> */}
            <div className="menu-icon" onClick={handleClick}>
              <i className={click ? "fas fa-times" : "fas fa-bars"} />
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link
                  to="/passes"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Buy Passes
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  to="/classchedule"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Class Schedule
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/gallery"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Gallery
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/ourTrainers"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Our Trainers
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/contactus"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Contact Us
                </Link>
              </li>

              {/* <li>
                <Link
                  to="/sign-up"
                  className="nav-links-mobile"
                  onClick={closeMobileMenu}
                >
                  Sign Up
                </Link>
              </li> */}
            </ul>
            {/* {button && <Button buttonStyle="btn--outline">SIGN UP</Button>} */}
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
