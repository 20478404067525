import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container hero">
      <video
        preload
        id="main-video"
        src="/GIFs/WEBVid.mp4"
        autoPlay="autoplay"
        muted="true"
        playsInline="true"
        loop
      />
      {/* not a gif a vid */}
      {/* <img
        id="main-video"
        src="/GIFs/WEBVid.mp4"
        alt="main video"
        style={{ width: "100%" }}
      /> */}
      <script>document.getElementById('main-video').play();</script>
      <h1>GROUP SESSIONS HAVE BEGUN!</h1>
      <p>What are you waiting for?</p>
      <div className="hero-btns">
        {/* <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          Buy Passes
        </Button> */}
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--medium"
          // onClick={console.log("click")}
        >
          Class Schedule
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
