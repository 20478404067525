import React from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import Home from "./components/pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ClassSchedule from "./components/pages/ClassSchedule";
import AboutUs from "./components/pages/AboutUs";
import SignUp from "./components/pages/SignUp";
import Shop from "./components/pages/shop/shop";
import "bootstrap/dist/css/bootstrap.min.css";
import OurTrainers from "./components/ourTrainers/ourTrainers";
import VideoGallery from "./components/videoGallery/videoGallery";
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/classchedule" component={ClassSchedule} />
          <Route path="/contactus" component={AboutUs} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/passes" component={Shop} />
          <Route path="/ourTrainers" component={OurTrainers} />
          <Route path="/gallery" component={VideoGallery} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
// import {Container} from 'react-bootstrap';

/*
<Container className="d-flex align-items-center jusify-content-center" style={{minHeight:"100vh"}}>
  <div className="w-100" style={{maxWidth: "400px"}}>
  <SignUp/>
  </div>
</Container> 
*/
