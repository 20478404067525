import React from "react";
import "../../App.css";
import { SocialIcon } from "react-social-icons";
import { NavLink } from "react-router-dom";
import Footer from "../Footer.js";

export default function ContactUs() {
  return (
    <div>
      <h1 style={{ margin: "50px" }}>Contact Us</h1>

      <p
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "auto",
          marginTop: "100px",
          fontWeight: "bold",
          fontSize: "30px",
        }}
      >
        <SocialIcon
          url="mailto:narbeh.ebrahimian@yahoo.com"
          network="email"
          bgColor="#000"
        ></SocialIcon>
        <a href="mailto:narbeh.ebrahimian@yahoo.com">
          narbeh.ebrahimian@yahoo.com
        </a>
      </p>
      <p
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "auto",
          marginTop: "20px",
          marginBottom: "100px",
          fontWeight: "bold",
          fontSize: "30px",
        }}
      >
        <SocialIcon
          url="mailto:narbeh.ebrahimian@yahoo.com"
          network="link"
          bgColor="#000"
        ></SocialIcon>
        <h2>Call Us:</h2>
        <a href="tel:8183699080"> 818-369-9080</a>
      </p>
      <Footer />
    </div>
  );
}
