import React, { useState } from "react";
import { Prev } from "react-bootstrap/esm/PageItem";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Checkout from "../../checkout/checkoutPopUp";
import ShopProduct from "./shopProduct";
import Footer from "../../Footer.js";
import Logo1 from "../shop/2x.jpg";
import Logo2 from "../shop/34x.jpg";
import Day1 from "../shop/day.jpg";
import Flyer from "../shop/FlyerNEWER.JPG";

// import Logo3 from "../shop/XMAS1.jpg";

const Shop = () => {
  const [item, setItem] = useState(null);
  const [show, setShow] = useState(false);

  const addCheckout = (data) => {
    setItem({ ...data });
  };
  const showPopUp = () => {
    setShow((Prev) => !Prev);
  };
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  console.log(item);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          paddingTop: "100px",
          position: "relative",

          flexWrap: "wrap",
          marginTop: "10px",
          marginBottom: "200px",
        }}
      >
        <div>
          <ShopProduct
            url={Flyer}
            title="New Membership Prices"
            // price={20}
            // showPopUp={toggle}
            // getData={addCheckout}
          />
        </div>
        {/* <div>
          <ShopProduct
            url={Logo1}
            title="2x/WEEK"
            price={150}
            showPopUp={toggle}
            getData={addCheckout}
          />
        </div>
        <div>
          <ShopProduct
            url={Logo2}
            title="3x/WEEK"
            price={199}
            showPopUp={toggle}
            getData={addCheckout}
          />
        </div> */}

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader>Choose payment method</ModalHeader>
          <ModalBody>
            <Checkout data={item} showPopUp={showPopUp} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};
export default Shop;

/* 
{show?( <div style={{position:'absolute',display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%',}} onClick={showPopUp}>
               <div onClick={(e)=>e.stopPropagation()}>
               
                </div>
            </div>):null} */
