import React from "react";
import Footer from "../Footer";

const VideoGallery = () => {
  const videos = [
    {
      url: "https://www.youtube.com/watch?v=8vslDwBrYgY",
      title:
        "Performance Academy - Workouts By Narb & Lil (Wedding Day Special)",
    },
    {
      url: "https://www.youtube.com/watch?v=qunFLtlPSac&t=1280s",
      title: "Performance Academy - Workouts by Narb (1000 Calorie Challenge)",
    },
    {
      url: "https://www.youtube.com/watch?v=Xsqs4cc3cgY&t=3810s",
      title: "Performance Academy - Workouts by Narb (1000 Calorie Challenge)",
    },
    {
      url: "https://www.youtube.com/watch?v=HlqXRCYOAQc&t=1497s",
      title:
        "Performance Academy - Workouts By Narb (Cardio Strength Superset)",
    },
    {
      url: "https://www.youtube.com/watch?v=BBVBjLuujvg&t=2033s",
      title: "Performance Academy - Workouts by Narb (Tabata Special)",
    },
    {
      url: "https://www.youtube.com/watch?v=jwGCLDcpr4A",
      title:
        "Performance Academy - Workouts by Narb (Total Body Toning; Towel Workout)",
    },
    {
      url: "https://www.youtube.com/watch?v=6ZnzN-90zgg&t=2787s",
      title:
        "Performance Academy - Workouts by Narb // Chair & Towel Workout (Total Body Strength!)",
    },
    {
      url: "https://www.youtube.com/watch?v=jr9ouZoMTxw&t=2525s",
      title:
        "Performance Academy- Workouts by Narb // Calorie Burner Challenge",
    },
    {
      url: "https://www.youtube.com/watch?v=fvramqt8-iQ&t=1083s",
      title:
        "Performance Academy - Workouts By Narb (Water Bottle Workout- Upper Body Strength + Cardio)",
    },
    {
      url: "https://www.youtube.com/watch?v=IyC3tsnrm0s&t=2401s",
      title: "Performance Academy - Workouts by Narb (Sculpting with Weights)",
    },
  ];
  return (
    <div>
      <h1 style={{ margin: "50px" }}>Our Videos</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: "200px",
        }}
      >
        {videos.map((item) => {
          return (
            <div style={{ width: "360px", margin: "5px" }}>
              <iframe
                width="100%"
                height="215"
                src={`https://www.youtube.com/embed/${
                  item.url.split("v=")[1].split("&")[0]
                }`}
                title="YouTube video player"
                frameborder="0"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
              {item.title ? (
                <p style={{ textAlign: "center" }}>{item.title}</p>
              ) : null}
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
};
export default VideoGallery;
