import React from "react";
//import Calendar from 'react-calendar';
import Footer from "../Footer";
import "../../App.css";
import "react-calendar/dist/Calendar.css";
import Schedule from "./sched.jpg";

import Shedule from "../Shedule/Shedule";
import CalendarComponent from "../calendar/calendar";

export default function ClassSchedule() {
  return (
    <div>
      <h1 style={{ margin: "50px" }}>Class Schedule</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          width: "100%",
          marginTop: "20px",
          marginBottom: "200px",
        }}
      >
        {/* <Shedule/> */}
        {/* <CalendarComponent /> */}
        <img style={{ width: 500, height: 600}} src={Schedule}/>
      </div>
      <Footer />
    </div>
  );
}
