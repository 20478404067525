import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "./App.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

class Shedule extends Component {
  state = {
    events: [
      {
        start: moment().toDate(),
        end: moment().add(1, "days").toDate(),
        title: "Some title",
      },
    ],
    dayLayoutAlgorithm: "no-overlap",
  };
  handleSelect = ({ start, end }) => {
    const title = window.prompt("New Event name");
    if (title)
      this.setState({
        events: [
          ...this.state.events,
          {
            start,
            end,
            title,
          },
        ],
      });
  };

  render() {
    return (
      <div className="App">
        <Calendar
          selectable={true}
          localizer={localizer}
          defaultDate={new Date()}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultView="month"
          events={this.state.events}
          style={{ height: "80vh", maxWidth: "940px" }}
          onSelectEvent={(event) => alert(event.title)}
          onSelectSlot={this.handleSelect}
          dayLayoutAlgorithm={this.state.dayLayoutAlgorithm}
        />
      </div>
    );
  }
}

export default Shedule;
