import React from "react";

import PayWithPayPal from "./payPal";
import PayButton from "./stripe/paymentStripebutton";
import "./buttonStyle.css";
import { Button } from "reactstrap";
const ShopProduct = ({ url, title, price, getData, showPopUp }) => {
  return (
    <div
      style={{
        display: "flex",
        minWidth: "200px",
        flexDirection: "column",
        justifyContent: "space-evenly",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          maxWidth: "300px",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={url} style={{ width: "120%", height: "120%" }} alt="" />
        <p style={{ textAlign: "center" }}>{title}</p>
        <p style={{ textAlign: "center", fontSize: "40px" }}>{price}</p>

        {/* <Button
          onClick={() => {
            getData({ name: title, value: price });
            showPopUp();
          }}
          className="onClickButton"
          color="secondary"
        >
          Buy
        </Button> */}
      </div>
    </div>
  );
};
export default ShopProduct;
// <PayWithPayPal items={[{name:title,value:price}]} total={price}/>
//<PayButton/>
