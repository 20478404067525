import React from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link, NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import { SocialIcon } from "react-social-icons";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        {/* <p className='footer-subscription-heading'>
          Join the Adventure newsletter to receive our best vacation deals
        </p> */}
        {/* <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p> */}
        <div className="input-areas">
          <form>
            {/* <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Your Email"
            /> */}
            {/* <Button buttonStyle="btn--outline">Subscribe</Button> */}
          </form>
        </div>
      </section>
      <div class="footer-links">
        {/* <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>About Us</h2>
            <Link to="/ourTrainers">Our Trainers</Link>
            {/* <Link to='/sign-up'>How it works</Link> */}
        {/* <Link to="/">Careers</Link> */}
        {/* <Link to="/">Investors</Link> */}
        {/* <Link to="/">Terms of Service</Link> */}
        {/* </div> */}
        {/*
        </div> */}
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>Gallery</h2>
            <Link to="/gallery">Videos</Link>
            <Link to="/gallery">Photos</Link>
            {/* <Link to="/">Agency</Link>
            <Link to="/">Influencer</Link> */}
          </div>
          <div class="footer-link-items">
            <h2>Social Media</h2>
            <a target="_blank" href="https://www.instagram.com/p_academy/">
              Instagram
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC9P2xqcwPeBw1OdvQrz1VGQ"
            >
              Youtube
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/performanceacademyllc/"
            >
              Facebook
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/narbeh-ebrahimian-536778a7/"
            >
              Linkedin
            </a>
            {/* <Link to="/">Instagram</Link>
            <Link to="/">Facebook</Link>
            <Link to="/">Youtube</Link>
            <Link to="/">Twitter</Link> */}
          </div>
        </div>
      </div>
      <section class="social-media">
        <div class="social-media-wrap">
          <div class="footer-logo">
            <Link to="/" className="social-logo">
              <img
                src="/images/PremiereLogo.png"
                width={182}
                height={182}
                alt=""
              />
              {/* <i class='fab fa-typo3' /> */}
            </Link>
          </div>
          <small class="website-rights">Performance Academy LLC. © 2020</small>
          <div class="social-icons">
            <SocialIcon
              url="https://www.instagram.com/p_academy/"
              bgColor="#fff"
            ></SocialIcon>
            <SocialIcon
              url="https://www.youtube.com/channel/UC9P2xqcwPeBw1OdvQrz1VGQ"
              bgColor="#fff"
              network="youtube"
            ></SocialIcon>
            <SocialIcon
              url="https://www.facebook.com/performanceacademyllc/"
              network="facebook"
              bgColor="#fff"
            ></SocialIcon>
            <SocialIcon
              url="https://www.linkedin.com/in/narbeh-ebrahimian-536778a7/"
              network="linkedin"
              bgColor="#fff"
            ></SocialIcon>

            {/* <Link
              class="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="Twitter"
            >
              <i class="fab fa-twitter" />
            </Link> */}
            {/* <Link
              class="social-icon-link twitter"
              url="https://www.linkedin.com/in/narbeh-ebrahimian-536778a7/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i class="fab fa-linkedin" />
            </Link> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
